/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import URL from "constant/url";
// import { useNavigate } from "react-router-dom";

import URL from "constant/url";
import BuyProductBanner from "./BuyProductBanner";

export default function Footer() {
  // const navigate = useNavigate();
  // const domain = window.location.hostname;
  // const logo_r = `https://buffer.httv.workers.dev/assets/${domain}/logo/logo-r.png`;

  return (
    <>
      <BuyProductBanner />
      <div id="footer01">
        {/*Footer Section*/}
        <section className="padding_top_75 padding_bottom_75 footer01_section bg_eef6fe">
          <div className="container">
            <div className="outer_logo_box">
              <div className="footer01_logo float-left">
                <a href="/">
                  <figure>
                    <img
                      src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-r.png`}
                      alt=""
                    />
                  </figure>
                </a>
              </div>
              <div className="copyright_box float-right">
                Copyright © Since 2021 SELLVPS247, All rights reserved
              </div>
            </div>
            <div className="row padding_top_75 outer_footer01_content_box">
              <div className="col-xl-5 col-lg-5 col-md-12 footer01_content_box">
                <p className="margin_bottom_30">
                  Đơn vị hàng đầu cung cấp các giải pháp hạ tầng IT, điện toán
                  đám mây. Chất lượng tốt nhất
                  <br /> Chi phí rẻ nhất
                  <br /> An toàn - Bảo mật
                  <br /> Hỗ trợ tận tâm 24/7.
                </p>
                <ul className="footer01_social_links">
                  <li>
                    <a href="https://www.facebook.com/Sellvps247-autocom-101166295719935">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <ul className="footer01_links_list">
                  <li>
                    <a href={URL.Agreement}>Điều khoản</a>
                  </li>
                  <li>
                    <a href={URL.SLA}>SLA</a>
                  </li>
                  <li>
                    <a href={URL.ProductManagement}>Quản trị</a>
                  </li>
                </ul>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <ul className="footer01_contact_list">
                  <li>Nguyễn Tri Phương, P8, Q5, TPHCM.</li>
                  <li>
                    <div>
                      <a href="tel:+ (84)708762402">+ (84)708762402</a>
                    </div>
                  </li>
                  <li>
                    <div>
                      {" "}
                      <a href="mailto:admin@sellvps247-auto.com">
                        admin@sellvps247-auto.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/*Ends Here*/}
      </div>
    </>
  );
}
