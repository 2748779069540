import URL from "constant/url";

export default function BuyProductBanner() {
  return (
    <>
      {/*COA Section*/}
      <section className="coa_section padding_top_100 padding_bottom_100">
        <div className="container">
          <div className="section_heading text-center">
            <h2 className="color_fff">
              Trải nghiệm dịch vụ chất lượng cùng SELLVPS247
            </h2>
            <p>
              Chúng tôi tự tin với kinh nghiệm nhiều năm kinh doanh, sẵn sàng đem
              đến cho khách hàng những dịch vụ chất lượng nhất.
            </p>
            <a href={URL.BuyProduct} className="btn_coa">
              Trải nghiệm ngay
            </a>
          </div>
        </div>
      </section>
      {/*Ends Here*/}
    </>
  );
}
