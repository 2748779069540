import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <>
      <section className="blog_banner bg_edf4ff">
        <div className="container">
          <div className="banner_content_box sub_page_banner">
            <h1>Hướng dẫn sử dụng</h1>
            <span style={{ color: "white", fontSize: "18px" }}>
              Chia sẻ và tổng hợp các hướng dẫn sử dụng kĩ thuật
              cần thiết.
            </span>
          </div>
        </div>
      </section>
      <section className="padding_top_40 padding_bottom_100 bg_d8eff5 blog_section text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
              <div className="blog_post_box">
                <figure>
                  <img
                    src="assets/images/index_blog_post_img01.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <div className="blog_content_box">
                  <div className="date_box">10 Tháng 5, 2021</div>
                  <a
                    href={URL.Guide1}
                    className="mb-2"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    Hướng dẫn tăng cường bảo mật VPS
                  </a>
                  <p style={{ fontSize: "16px", fontWeight: 400 }}>
                    Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS.
                    Hãy đọc bài viết sau để biết thêm chi tiết!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
              <div className="blog_post_box">
                <figure>
                  <img
                    src="assets/images/index_blog_post_img02.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <div className="blog_content_box">
                  <div className="date_box">15 Tháng 3, 2021</div>
                  <a
                    href={URL.Guide2}
                    className="mb-2"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                  </a>
                  <p style={{ fontSize: "16px", fontWeight: 400 }}>
                    Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng dòng
                    lệnh hoặc cài đặt trong User Accounts...
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="blog_post_box">
                <figure>
                  <img
                    src="assets/images/index_blog_post_img03.png"
                    alt=""
                    className="img-fluid"
                  />
                </figure>
                <div className="blog_content_box">
                  <div className="date_box">19 Tháng 8, 2020</div>
                  <a
                    href={URL.Guide3}
                    className="mb-2"
                    style={{ fontSize: "20px", fontWeight: 650 }}
                  >
                    Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows
                  </a>
                  <p style={{ fontSize: "16px", fontWeight: 400 }}>
                    Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ
                    Disk Management...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
