/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */

export default function Contact() {
  return (
    <>
      <section className="sub_page_banner_section">
        <div className="container">
          <div className="row banner_row sub_page_banner_content">
            <div className="col-xl-8 col-lg-6 col-md-6 order02 banner_content_box sub_page_banner_content_inner">
              <h1>Hãy liên hệ!</h1>
              <p>
                Thời gian là tiền bạc! Chúng tôi xem trọng khách hàng và thời
                gian của mọi người, vì vậy, đội ngũ của chúng tôi luôn làm tốt
                nhất có thể để giúp bạn giải quyết vấn đề nhanh chóng!
              </p>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 order01">
              <figure>
                <img src="assets/images/sub_page_banner_img06.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <div
        className="guarantee_support_section"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <div className="container">
          <div className="row">
            <section className="">
              <div className="container">
                <div className="contact_details_box">
                  <div className="row">
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.7199693905095!2d106.66703047570314!3d10.756051759567843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752efb9d7818e9%3A0x6d87007def6c1eb3!2zxJAuIE5ndXnhu4VuIFRyaSBQaMawxqFuZywgUXXhuq1uIDUsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1700674691348!5m2!1svi!2s"
                        width="100%"
                        height="350"
                        loading="lazy"
                      ></iframe>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <h2>Thông tin liên hệ</h2>
                      <p
                        className="pp"
                        style={{
                          marginTop: "16px",
                          marginBottom: "8px",
                        }}
                      >
                        <a
                          href="tel:+(84)708762402"
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            fontWeight: 500,
                          }}
                        >
                          Phone: +(84)708762402
                        </a>
                      </p>
                      <p
                        className="pp"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        <a
                          href="https://t.me/nickchatdelamgi"
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            fontWeight: 500,
                          }}
                        >
                          Telegram: @nickchatdelamgi
                        </a>
                      </p>
                      <p
                        className="pp"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        <a
                          href="skype:live:nickchatdelamgi_2?chat"
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            fontWeight: 500,
                          }}
                        >
                          Skype: live:nickchatdelamgi_2
                        </a>
                      </p>
                      <p
                        className="pp"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        <a
                          href="https://www.facebook.com/Sellvps247-autocom-101166295719935"
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            fontWeight: 500,
                          }}
                        >
                          Facebook:
                          https://www.facebook.com/Sellvps247-autocom-101166295719935
                        </a>
                      </p>
                      <p
                        className="pp"
                        style={{
                          marginBottom: "8px",
                        }}
                      >
                        <a
                          href="https://mmo4me.com/threads/youandivn-trung-gian-24-7.425637/"
                          style={{
                            color: "#007bff",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            fontWeight: 500,
                          }}
                        >
                          Bảo Hiểm MMO4ME:
                          https://mmo4me.com/threads/youandivn-trung-gian-24-7.425637/
                        </a>
                      </p>
                      <p />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
