/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import URL from "constant/url";
import FeaturePlan from "components/FeaturePlan";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Content() {
  return (
    <>
      {/*Hero Banner Section*/}
      <section className="home_banner_section">
        <div className="home_banner_inner_section">
          <div className="container">
            <div className="row banner_row">
              <div className="col-xl-6 col-lg-6 col-md-6 order02 banner_content_box">
                <small>Dịch vụ</small>
                <h1>MÁY CHỦ ẢO</h1>
                <p>Đa dạng, Tự động, Giá hợp lý</p>
                <a href={URL.BuyProduct} className="banner_btn">
                  Mua ngay
                </a>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 order01 home_banner_img_box" />
            </div>
          </div>
        </div>
        <div id="mouse-scroll" data-scroll-nav={2}>
          <div className="mouse">
            <div className="mouse-in" />
          </div>
          <div>
            <span className="down-arrow-1" />
            <span className="down-arrow-2" />
            <span className="down-arrow-3" />
          </div>
        </div>
      </section>
      {/*End Slider Section*/}
      <>
        <section
          className="padding_top_100 padding_bottom_100 text-center"
          data-scroll-index={2}
        >
          <div className="container">
            <div className="section_heading margin_bottom_80">
              <h2 className="color_051646">Những Plan Tiêu Biểu</h2>
              <p>Cấu hình tối ưu hóa với giá cả hợp lý nhất.</p>
            </div>
            <FeaturePlan />
          </div>
        </section>
        {/*Welcome section*/}
        <section className="padding_top_100 padding_bottom_100 bg_d8eff5 welcome_section text-center">
          <div className="container">
            <div className="section_heading margin_bottom_50 welcome_content_box">
              <h2 className="color_051646">
                Đa dạng tùy chọn, đa dạng Hệ điều hành
              </h2>
              <p className="color_text">
                Quý khách có thể tùy chọn cài đặt máy chủ tùy theo nhu cầu, cài
                đặt các phần mềm cơ bản, cài sẵn hệ điều hành cần thiết. Toàn bộ
                quá trình được vận hành tự động đảm bảo an toàn, bảo mật thông
                tin khách hàng.
              </p>
            </div>
            <figure>
              <img src="assets/images/svg/welcome_section_img.svg" alt="" />
            </figure>
          </div>
        </section>
        {/*Ends Here*/}
        {/*Counter Post*/}
        <section className="counter_section padding_top_50 padding_bottom_50">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 margin_mobile">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-12">
                    <div className="counter_img_outer">
                      <figure>
                        <img src="assets/images/counter_img01.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 no_padding col-12">
                    <div className="counter_type">
                      Đường mạng tốc độ cao 1 Gbps.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6 margin_mobile">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="counter_img_outer">
                      <figure>
                        <img src="assets/images/counter_img02.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 no_padding">
                    <div className="counter_type">IPv4 riêng cho mỗi VPS.</div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="counter_img_outer">
                      <figure>
                        <img src="assets/images/counter_img03.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 no_padding">
                    <div className="counter_type">
                      Ổ cứng SSD, phần cứng mạnh mẽ.
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-6">
                <div className="row">
                  <div className="col-xl-5 col-lg-5">
                    <div className="counter_img_outer">
                      <figure>
                        <img src="assets/images/counter_img04.png" alt="" />
                      </figure>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 no_padding">
                    <div className="counter_type">
                      Quản lý tự động hoàn toàn.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Ends Here*/}
        {/*Feature Section*/}
        <section className="padding_top_100 padding_bottom_100 text-center">
          <div className="container">
            <div className="section_heading margin_bottom_80">
              <h2 className="color_051646">Tại Sao Chọn SELLVPS247</h2>
            </div>
            <div className="row margin_bottom_50">
              <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img01.svg" alt="" />
                  </figure>
                  <h4>SSD Chuyên Dụng</h4>
                  <p>
                    Sử dụng những phần cứng bền bỉ và ổ SSD để mang lại trải
                    nghiệm tốt nhất cho khách hàng.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img02.svg" alt="" />
                  </figure>
                  <h4>Dễ Dàng Cài Đặt</h4>
                  <p>
                    Mọi thao tác cài đặt và quản lý đều tự động và dễ dàng sử
                    dụng trong phần Quản Trị.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img03.svg" alt="" />
                  </figure>
                  <h4>Tiêu Chuẩn Quốc Tế</h4>
                  <p>
                    Máy chủ được đặt và vận hành tại các Trung tâm dữ liệu Tier
                    3 chuẩn quốc tế.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img04.svg" alt="" />
                  </figure>
                  <h4>Hỗ Trợ 24/7</h4>
                  <p>
                    Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng cho
                    mọi tình huống, hỗ trợ nhanh chóng.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 margin_mobile">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img05.svg" alt="" />
                  </figure>
                  <h4>VPS Đa Dạng</h4>
                  <p>
                    Đa dạng quốc gia, giá cả phù hợp với cấu hình, hỗ trợ cài
                    đặt Windows và Linux Free.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className="feature_box">
                  <figure>
                    <img src="assets/images/svg/feature_img06.svg" alt="" />
                  </figure>
                  <h4>Chính Sách Hoàn Trả</h4>
                  <p>
                    Nếu quý khách không hài lòng với dịch vụ, chúng tôi sẽ hỗ
                    trợ hoàn lại tiền không cần lý do.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Ends Here*/}
      </>
    </>
  );
}
